import React, { FC, lazy, Suspense, useState, useEffect } from "react";
// External Components
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FlagsProvider } from "flagged";
// Internal Components
import {
  theme,
  ErrorBoundary,
  LoadingBox,
  UserContext,
  UserInfo,
} from "@vitm/components";
import jwtManager from "./jwtManager";
import { LOGIN_PATH, ROOT_PATH } from "./paths";
import PrivateRoute from "./components/common/PrivateRoute";

// Lazy imports
const Login = lazy(() => import("./components/Login"));
const Root = lazy(() => import("./screens/Root"));

const flagFeatures = {
  devEnvironment: process.env.REACT_APP_HIDE_FLAGGED_FEATURES !== "true",
  exposeInsights: process.env.REACT_APP_HIDE_DATA_INSIGHTS !== "true",
};
const App: FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [triedJwtRefresh, setTriedJwtRefresh] = useState(false);

  useEffect(() => {
    // NOTE Try once to refresh jwt token
    (async () => {
      const refreshJwtToken = await jwtManager.refresh();
      if (refreshJwtToken?.replyStatus === 200) {
        setUserInfo(refreshJwtToken);
      }
      setTriedJwtRefresh(true);
    })();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <FlagsProvider features={flagFeatures}>
          <CssBaseline />
          <ErrorBoundary>
            {triedJwtRefresh ? (
              <Router>
                <Suspense fallback={<LoadingBox />}>
                  <UserContext.Provider value={userInfo}>
                    <Switch>
                      <Route
                        path={LOGIN_PATH}
                        component={() => <Login setUserInfo={setUserInfo} />}
                      />
                      <PrivateRoute
                        path={ROOT_PATH}
                        component={() => <Root setUserInfo={setUserInfo} />}
                      />
                    </Switch>
                  </UserContext.Provider>
                </Suspense>
              </Router>
            ) : (
              <LoadingBox />
            )}
          </ErrorBoundary>
        </FlagsProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
