import jwtDecode from "jwt-decode";
import { isPast } from "date-fns";

const arrayItems = (results: string) => results.replace(/{|}/g, "").split(",");

const handleError = (err: Error) => {
  if (window.Raven) {
    window.Raven.captureException(err);
  }
};

export class JwtManager {
  jwtToken: string | null = null;

  private refreshTimerId: number | null = null;

  private refreshTime: number = 30 * 1000;

  private expiresTime: Date = new Date();

  /**
   * In case a token has an absurd expiration time, we still will refresh after 10 mins at most.
   */
  private maxRefreshTime: number = 10 * 60 * 1000;

  scheduleRefresh(ms: number) {
    this.stopTimer();
    this.refreshTimerId = window.setTimeout(
      () => this.refresh(),
      Math.min(ms, this.maxRefreshTime),
    );
  }

  stopTimer() {
    if (this.refreshTimerId) {
      window.clearTimeout(this.refreshTimerId);
    }
  }

  async refresh() {
    let result;
    try {
      result = await fetch("/v1/refresh", {
        method: "GET",
      });
    } catch {
      this.scheduleRefresh(10000);
    }
    if (result) return this.processResponse(result);
    return undefined;
  }

  async processResponse(result: Response) {
    const jwtUserInfo: JwtUserInfo = {
      needsReset: true,
      userAccountIds: [],
      userRole: "",
      userId: "",
      userEmail: "",
      replyStatus: 200,
    };
    if (result.ok) {
      this.jwtToken = await result.text();
      const {
        sub,
        "https://hasura.io/jwt/claims": decoded,
        exp,
      } = jwtDecode<Token>(this.jwtToken);
      this.expiresTime = new Date(exp * 1000);
      const msToRefresh =
        this.expiresTime.getTime() - new Date().getTime() - this.refreshTime;
      this.scheduleRefresh(msToRefresh);
      if (decoded["x-hasura-account-ids"]) {
        jwtUserInfo.userAccountIds = arrayItems(
          decoded["x-hasura-account-ids"],
        );
      }
      jwtUserInfo.userRole = decoded["x-hasura-default-role"];
      jwtUserInfo.userId = decoded["x-hasura-user-id"];
      jwtUserInfo.userEmail = sub;
      // add x-temporary-password
      jwtUserInfo.needsReset = decoded["x-temporary-password"];
      return jwtUserInfo;
    }
    jwtUserInfo.replyStatus = result.status;
    return jwtUserInfo;
  }

  async logIn({ email, password }: Credentials) {
    const result = await fetch("/v1/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email,
        password,
      }),
    });
    return this.processResponse(result);
  }

  async logOut() {
    this.stopTimer();
    try {
      const result = await fetch("/v1/logout", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!result.ok) {
        handleError(new Error(await result.text()));
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err);
      }
    } finally {
      if (window.analytics) {
        window.analytics.page("Log Out");
      }
      this.jwtToken = null;
    }
  }

  /**
   * Returns true if the jwtToken stored by this class has expired and false if it has not.
   */
  expired() {
    return isPast(this.expiresTime);
  }
}

export interface Credentials {
  email: string;
  password: string;
}

interface JwtUserInfo {
  userAccountIds: string[];
  userRole: string;
  userId: string;
  userEmail: string;
  replyStatus: number;
  needsReset: boolean;
}

interface Token {
  sub: string;
  exp: number;
  "https://hasura.io/jwt/claims": {
    "x-hasura-account-ids"?: string;
    "x-hasura-default-role": string;
    "x-hasura-user-id": string;
    "x-temporary-password": boolean;
  };
}

export default new JwtManager();
