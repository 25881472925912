export const LOGIN_PATH = "/login";
// NOTE These params need to be optional so secureRoute can correctly route
export const ROOT_PATH = "/:page?/:accountId?/:otherParams*";
// Truck Map
export const TRUCK_MAP_ROOT = "/truckmap";
export const TRUCK_MAP_PATH = `${TRUCK_MAP_ROOT}/:accountId?`;
// User Admin
export const USER_ADMIN_ROOT = "/useradminstration";
export const USER_ADMIN_PATH = `${USER_ADMIN_ROOT}/:accountId/:userType`;
export const USER_ADMIN_ADD_USER_PATH = `${USER_ADMIN_PATH}/add`;
export const USER_ADMIN_EDIT_USER_PATH = `${USER_ADMIN_PATH}/edit/:userId`;
export const USER_ADMIN_TABLE_PATH = `${USER_ADMIN_PATH}/:pageNumber`;
export const EXTERNAL_USER_PATH = `${USER_ADMIN_ROOT}/:accountId/user`;
export const INTERNAL_USER_PATH = `${USER_ADMIN_ROOT}/:accountId/internaluser`;
// Load Report
export const LOAD_REPORTS_ROOT = "/loadreports";
export const LOAD_REPORTS_PATH = `${LOAD_REPORTS_ROOT}/:accountId?`;
export const LOAD_REPORTS_TABLE_PATH = `${LOAD_REPORTS_PATH}/:pageNumber`;
export const LOAD_REPORTS_DETAIL_PATH = `${LOAD_REPORTS_PATH}/:truckId/:lastLoadTime/:tab`;
// Batch Assistant
export const BATCH_ASSISTANT_ROOT = "/batchassistant";
export const BATCH_ASSISTANT_PATH = `${BATCH_ASSISTANT_ROOT}/:accountId/:day`;
export const BATCH_ASSISTANT_TABLE_PATH = `${BATCH_ASSISTANT_PATH}/:pageNumber`;
// Change Password
export const CHANGE_PASSWORD_ROOT = "/password";
export const CHANGE_PASSWORD_PATH = `${CHANGE_PASSWORD_ROOT}/:accountId?`;
// Error
export const ERROR_ROOT = "/error";
export const ERROR_PATH = "/error/:accountId?";
// User Preferences
export const PREFERENCES_ROOT = "/preferences";
export const PREFERENCES_PATH = `${PREFERENCES_ROOT}/:accountId?`;
// Tickets
export const TICKETS_ROOT = "/tickets";
export const TICKETS_PATH = `${TICKETS_ROOT}/:accountId/:day`;
export const TICKETS_TABLE_PATH = `${TICKETS_PATH}/:pageNumber`;
// Slump Override
export const SLUMP_ROOT = "/slumpoverride";
export const SLUMP_PATH = `${SLUMP_ROOT}/:accountId?`;
export const CREATE_NEW_OVERIDE_URL = `${SLUMP_PATH}/createNewOverride`;
