import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { UserContext } from "@vitm/components";
import { LOGIN_PATH } from "../../paths";

export default (routerProps: RouteProps) => {
  const { location } = routerProps;
  const jwtUserInfo = useContext(UserContext);
  if (jwtUserInfo) {
    return <Route {...routerProps} />;
  }
  return (
    <Redirect
      to={{
        pathname: LOGIN_PATH,
        state: { from: location },
      }}
    />
  );
};
